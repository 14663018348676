@import '../../../../scss/theme-bootstrap';

.ie-upgrade {
  background: $cr19-bg-dark-transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  html.ie8 &,
  html.ie7 &,
  html.ie6 & {
    background-color: $cr19-text-black;
  }
  &__content {
    @include transform(translate(-50%, -50%));
    background-color: $cr19-text-white;
    color: $cr19-text-black;
    height: 320px;
    left: 50%;
    max-width: 720px;
    padding: 60px 50px;
    position: relative;
    top: 50%;
    width: 60%;
    z-index: 10001;
    html.ie8 &,
    html.ie7 &,
    html.ie6 & {
      left: 20%;
      top: 20%;
    }
    .ie-upgrade__close {
      background: url('/sites/clinique/themes/cl_base/img/icon-close.png') no-repeat;
      border: none;
      box-sizing: content-box;
      height: 25px;
      position: absolute;
      right: 20px;
      top: 25px;
      width: 25px;
    }
    .ie-upgrade__body {
      display: inline-block;
      font-size: 15px;
      margin-top: 5px;
    }
    p {
      line-height: inherit;
    }
  }
  &__button {
    .page-footer & {
      font-size: 1.3em;
      line-height: 1.5;
      margin-top: 20px;
      padding: 6px 18px;
    }
  }
}
